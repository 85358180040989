<template>
  <div class="flex-box vertical content">
    <div class="data-title">
      <div class="rec-title-text">共{{ pagination.total }}条记录</div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item label="设备名称">
          <a-input v-model="form.name" placeholder="请输入设备名称" allowClear></a-input>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="getList">查询</a-button>
      </a-space>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ y: h }"
        @change="handleTableChange"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import { isEmpty, getKeyTitle } from "../../common/js/tool";
import { deviceStatusOptions } from "../../common/constant/safe";

export default {
  name: "WaterList",
  data() {
    return {
      type: 2, // 水压
      h: 500,
      list: [],
      form: {},
      loading: false,
      columns: [
        { title: "设备名称", dataIndex: "name" },
        { title: "设备编号", dataIndex: "device_num" },
        { title: "所属楼栋", customRender: (text) => <span>{text.other_info?.build}</span> },
        { title: "设备厂商", dataIndex: "manufacturer" },
        { title: "设备型号", dataIndex: "device_model" },
        { title: "最小阙值", customRender: (text) => <span>{text.other_info?.min_threshold}</span> },
        { title: "最大阙值", customRender: (text) => <span>{text.other_info?.max_threshold}</span> },
        { title: "当前值", customRender: (text) => <span>{text.other_info?.current_value}</span> },
        {
          title: "设备状态",
          dataIndex: "device_status",
          customRender: (text) => <span>{getKeyTitle(deviceStatusOptions, text, "value", "label")}</span>,
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      searchKeyType: {
        name: 2,
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    dealList(list) {
      if (list) {
        list.forEach((item) => {
          if (item.other_info) {
            item.other_info = JSON.parse(item.other_info);
          }
        });
      }
      return list;
    },
    getList() {
      this.loading = true;
      let url = `/admin/security-device?sort=-id&filter[type]=${this.type}`;
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      url += this.getQuery();
      this.$axios({ url, noTempleFilter: false })
        .then((res) => {
          this.loading = false;
          let list = this.dealList(res.data);
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    getQuery(ex) {
      let query = "";
      let form = this.form;
      let searchKeyType = this.searchKeyType;
      for (let key in form) {
        if (!isEmpty(form[key])) {
          //模糊搜索
          let type = searchKeyType[key] || 0;
          if (typeof type == "function") {
            query += type(form, key);
          } else if (type == 1) {
            query += `&filter[${key}][like]=${form[key]}`;
          } else if (type == 2) {
            query += `&${key}=${form[key]}`;
          } else {
            if (ex) {
              query += `&${key}=${form[key]}`;
            } else {
              query += `&filter[${key}]=${form[key]}`;
            }
          }
        }
      }
      return ex ? query.replace("&", "?") : query;
    },
  },
};
</script>

<style lang="less" scoped>
.ant-input-affix-wrapper {
  width: 180px;
}
</style>
